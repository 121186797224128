import { Component } from '@angular/core';
import { AuthenticationService } from '../../../core/service/authentication.service';
import { ReportService } from '../../../core/service/report.service';
import { NotifierModule, NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IPurchase } from '../../../core/models/IPurchase';
import { AgGridAngular } from 'ag-grid-angular';
import moment from 'moment';
import { UserStoreMapping } from '../../../core/models/IUserProfile';

@Component({
  selector: 'app-purchase-report',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NotifierModule, AgGridAngular],
  templateUrl: './purchase-report.component.html',
  styleUrl: './purchase-report.component.css'
})
export class PurchaseReportComponent {
  detailGridApi: any;
  storeId: number = 0;
  userStoreMapping!: UserStoreMapping;
  purchaseReportInformation: IPurchase[] = [];
  purchaseReportForm: FormGroup | undefined;
  constructor(private authService: AuthenticationService, private reportService: ReportService, private fb: FormBuilder, private notifier: NotifierService) { }
  ngOnInit(): void {
    this.authService.store$.subscribe(m => {
      this.userStoreMapping = m;
    });
    this.purchaseReportForm = this.fb.group({
      fromDate: ['', [Validators.required]],
      toDate: ['', [Validators.required]],
      search: []
    });
  }

  detailColumnDefs = [
    { headerName: 'Date', field: 'date', valueFormatter: this.dateFormatter,editable: false, width: 300 },
    { headerName: 'Name', field: 'name', editable: false },
    { headerName: 'Rack', field: 'rack', editable: false },
    { headerName: 'Level', field: 'level', editable: false },
    { headerName: 'Total Box', field: 'totalBox', editable: false },
    { headerName: 'Quantity Per Box', field: 'quantityPerBox', editable: false },
    { headerName: 'Quantity Loose', field: 'quantityLoose', editable: false },
    { headerName: 'Total', field: 'total', editable: false }
  ];

  dateFormatter(params: any) {
    return moment(params.value).format('DD-MM-YYYY');
  }


  gridOptionsDetail = {
    columnDefs: this.detailColumnDefs,
    rowData: this.purchaseReportInformation,
    animateRows: true,
    onGridReady: (params: any) => {
      this.detailGridApi = params.api;
    },
    sortable: true, filter: true
  }

  get fromDate() {
    return this.purchaseReportForm?.get('fromDate');
  }

  get toDate() {
    return this.purchaseReportForm?.get('toDate');
  }
  get search() {
    return this.purchaseReportForm?.get('search');
  }
  generateReport() {
    this.detailGridApi.showLoadingOverlay();
    let { fromDate, toDate, search } = this.purchaseReportForm?.value;
    this.reportService.getPurchases(fromDate, toDate, search, this.userStoreMapping.storeId).subscribe((purchaseResponse: IPurchase[]) => {
    
      if (purchaseResponse.length == 0) 
        this.notifier.notify('warning', 'No record found!');
      
    this.purchaseReportInformation = purchaseResponse;
    this.detailGridApi.showLoadingOverlay();
    });
  }

  print() {
    window.print();
  }
}
