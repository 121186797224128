import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { IForm } from '../../../core/models/IForm';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { TabsModule } from 'primeng/tabs';
import { TreeTableModule } from 'primeng/treetable';

@Component({
  selector: 'app-form-builder',
  standalone: true,
  imports: [TreeTableModule, TabsModule, CommonModule, FormsModule, ReactiveFormsModule, AgGridAngular],
  templateUrl: './form-builder.component.html',
  styleUrl: './form-builder.component.css'
})
export class FormBuilderComponent implements OnInit {

  @Input() form: IForm[] = [];
  @Input() isShowSaveButton: boolean = true;
  @Output() requestBodyEmit = new EventEmitter<any>();
  @Output() selectedData = new EventEmitter<any>();
  @Output() deleteRows = new EventEmitter<any>();
  formGroup: FormGroup | undefined;
  @ViewChild('builderForm') formElement: any;
  constructor(private fb: FormBuilder) {

  }

  ngOnInit() {
    var obj: any = {};
    this.form.forEach(element => {

      element.SectionDetail.forEach(detail => {
        console.log(detail.MappingName,"#",detail.defaultValue)
        obj[detail.MappingName] = [detail.defaultValue ?? this.getValue(detail.IsAutoIncrement ?? false), detail.IsRequired ? Validators.required : []]
      })
    });

    this.formGroup = this.fb.group(obj);

  }

  gridApi: any;
  gridOptions = {
    sortable: true,
    filter: true,
    editable: true,
    animateRows: true,

  }

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  rowSelection = {
    mode: 'multiRow'
  };

  defaultColDef = {
    editable: true,
    sortable: true, filter: true,

  };

  delete() {
    this.deleteRows.emit();
  }
  onSelectionChanged() {
    const selectedRows = this.gridApi.getSelectedRows();
    this.selectedData.emit(selectedRows);
    console.log(selectedRows)
  }
  getValue(isAutoIncrement: boolean) {
    if (isAutoIncrement)
      return `${Date.now()}`
    else
      return "";
  }

  submitOnChange() {
    this.submit();
  }

  submit() {

    if (this.formGroup?.valid)
      this.requestBodyEmit.emit(this.generateBody(this.formGroup.value));

  }

  expandedNodes: any[] = [];  // Initially expand the first root node

  toggleNode(node: any) {
    // if (this.expandedNodes.includes(node)) {
    //   const index = this.expandedNodes.indexOf(node);
    //   if (index > -1) {
    //     this.expandedNodes.splice(index, 1);
    //   }
    // } else {
    //   this.expandedNodes.push(node);
    // }
    const index = this.expandedNodes.findIndex(expandedNode => expandedNode === node);

    if (index === -1) {
      // Node is not expanded, so expand it
      this.expandedNodes.push(node);
    } else {
      // Node is already expanded, so collapse it
      this.expandedNodes.splice(index, 1);
    }
    
  }

  generateBody(formData: any) {

    this.form.map(section => {
      section.SectionDetail.map(detail => {
        if (detail.InputType == "grid")
          formData[detail.MappingName] = detail.Data;
      });
    })
    return formData;
  }

}
