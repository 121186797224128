import { Component, HostListener } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { NotifierModule, NotifierService } from 'angular-notifier';
import { FormsModule } from '@angular/forms';
import { ProgressLoaderComponent } from '../../shared/progress-loader/progress-loader.component';
import { SearchPipe } from '../../shared/pipe/SearchPipe';
import { StockService } from '../../core/service/stock.service';
import { AuthenticationService } from '../../core/service/authentication.service';
import { UserStoreMapping } from '../../core/models/IUserProfile';


@Component({
  selector: 'app-stock-out',
  standalone: true,
  imports: [AgGridAngular, FormsModule, NotifierModule, ProgressLoaderComponent],
  templateUrl: './stock-out.component.html',
  styleUrl: './stock-out.component.css'
})
export class StockOutComponent {
  userStoreMapping!: UserStoreMapping
  constructor(private stockService: StockService,
    private authService: AuthenticationService,
    private notifier: NotifierService) {

  }
  ngOnInit(): void {
    this.authService.store$.subscribe(m => {
      this.userStoreMapping = m;
      this.ColDef()
    });
  }
  rowData: any[] = [];
  rowfilteredData: any[] = [];
  searchText: string = "";
  isLoading: boolean = false;
  colDefs!: any[]
  @HostListener('document:paste', ['$event'])
  async onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const pastedData = clipboardData.getData('text/plain');
      this.parseData(pastedData);
    }
  }
  ColDef() {
    this.colDefs =
      [

        { field: "Date" },
        { field: "SoNumber" },
        { field: "Rack" },
        { field: "Level" },
        { field: "Grade", headerName: this.userStoreMapping?.store?.lookup?.lookupItems?.filter(x => x.extra === 'attr1')[0]?.value ?? "Grade", },
        { field: "Type", headerName: this.userStoreMapping?.store?.lookup?.lookupItems?.filter(x => x.extra === 'attr2')[0]?.value ?? "Type" },
        { field: "Size", headerName: this.userStoreMapping?.store?.lookup?.lookupItems?.filter(x => x.extra === 'attr3')[0]?.value ?? "Size" },
        { field: "Finish", headerName: this.userStoreMapping?.store?.lookup?.lookupItems?.filter(x => x.extra === 'attr4')[0]?.value ?? "Finish" },
        { field: "Thread", headerName: this.userStoreMapping?.store?.lookup?.lookupItems?.filter(x => x.extra === 'attr5')[0]?.value ?? "Thread" },
        { field: "SoldQuantity", headerName: "Sold Quantity" },
      ]
  }
  parseData(pastedData: string) {
    var headers = this.colDefs.map((col) => col.field);
    const rows = pastedData.split('\n').map(row => row.trim()).filter(row => row);
    var pastedJson = rows.map(row => {
      const columns = row.split('\t').map(column => column.trim());
      return headers.reduce((acc, header, index) => {
        acc[header] = columns[index] || '';
        return acc;
      }, {});
    });
    if (pastedJson?.length > 100) {
      this.notifier.notify('warning', `There are ${pastedJson?.length} records available. We have selected the top 100 records.`);
    }
    var itemResponse = this.addNamesToInventory(this.getTop100Records(pastedJson));

    this.rowData = itemResponse;
    this.rowfilteredData = itemResponse;
  }

  getTop100Records(data: any) {
    return data
      .slice(0, 100);
  }

  addNamesToInventory(inventory: any) {
    return inventory.map((item: any) => {
      let name = '';

      if (item.Thread?.toLowerCase() === 'ft' || item.Thread?.toLowerCase() === 'ht') {
        name = `${item.Size}-${item.Grade}-${item.Finish}-${item.Type}-${item.Thread}`;
      } else {
        name = `${item.Size}-${item.Grade}-${item.Finish}-${item.Type}`;
      }

      return {
        ...item,
        name: this.modifyString(name)
      };
    });
  }

  modifyString(input: string) {
    if (input.startsWith('-')) {
      input = input.slice(1);
    }
    if (input.endsWith('-')) {
      input = input.slice(0, -1);
    }
    input = input.replace(/--/g, '-');

    return input.trim();
  }

  onCellValueChanged(params: any) {
  }
  defaultColDef = {
    editable: true, // Make all columns editable by default
    sortable: true, filter: true
  };
  SaveStock = () => {
    this.isLoading = true;
    this.stockService.saveStock(this.rowData, "Sold", "-", this.userStoreMapping.storeId).subscribe((resp) => {
      this.rowData = [];
      this.rowfilteredData = [];
      this.isLoading = false;
      this.notifier.notify('success', 'Stock Checkout Successfully!');

    }, () => {
      this.isLoading = false;
      this.notifier.notify('error', 'Stock Checkout fail!');
    })
  }
  handleDataChange() {
    const searchPipe = new SearchPipe();
    this.rowfilteredData = searchPipe.transform(this.rowData, this.searchText, ['SoNumber', 'Date', 'Rack', 'Level', 'Grade',
      'Type', 'Size', 'Finish', 'Thread']);
  }
}
