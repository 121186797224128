<div class="card">
    <div class="card-header">
        <h1 class="h3 mb-2 text-gray-800">Create Order</h1>
    </div>
    <div class="card-body">
        <app-form-builder [form]="createOrder" (requestBodyEmit)="saveOrder($event)" (selectedData)="selectedData($event)" (deleteRows)="deleteRows()" ></app-form-builder>
    </div>
</div>

<notifier-container></notifier-container>
<app-progress-loader [loading]="isLoading" ></app-progress-loader>
