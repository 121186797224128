import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as echarts from 'echarts';
import { AgGridAngular } from 'ag-grid-angular';
import { StockService } from '../../core/service/stock.service';
import { AuthenticationService } from '../../core/service/authentication.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [AgGridAngular],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnInit {
  @ViewChild('chartStock', { static: true }) chartStockElement!: ElementRef;
  @ViewChild('chartFutureStock', { static: true }) chartFutureStockElement!: ElementRef;

  constructor(private stockService: StockService, private authService: AuthenticationService) { }

  ngOnInit(): void {
    this.authService.store$.subscribe(m => {
      this.getStocks(m.storeId);
      this.getFutureStocks(m.storeId);
    });
  }
  
  private initStockChart(): void {
    const chart = echarts.init(this.chartStockElement.nativeElement);

    const options = {

      tooltip: {
        trigger: 'item',
      },
      legend: {
        orient: 'horizontal',
        left: 'center',
        type:'scroll'
      },
      series: [
        {
          name: 'Sales',
          type: 'pie',
          radius: '50%',
          data: this.productGraphInfo(),
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };

    chart.setOption(options);
  }

  private initFutureStockChart(): void {
    const chart = echarts.init(this.chartFutureStockElement.nativeElement);

    const options = {

      tooltip: {
        trigger: 'item',
      },
      legend: {
        orient: 'horizontal',
        left: 'center',
        type:'scroll'
      },
      series: [
        {
          name: 'Sales',
          type: 'pie',
          radius: '50%',
          data: this.futureProductGraphInfo(),
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };

    chart.setOption(options);
  }



  rowData: any[] = [];
  rowFutureData: any[] = [];
  gridApi: any;
  futureGridApi: any;
  columnDefs = [
    { headerName: 'Product Name', field: 'name', editable: false, width: 300 },
    { headerName: 'Stock', field: 'availableStock' }
  ];
  columnTransitDefs = [
    { headerName: 'Product Name', field: 'name', editable: false, width: 300 },
    { headerName: 'Transit Stock', field: 'availableStock' }
  ];
  gridOptions = {
    columnDefs: this.columnDefs,
    rowData: this.rowData,
    animateRows: true,
    onGridReady: (params: any) => {
      this.gridApi = params.api;
    }
  }
  gridFutureOptions = {
    columnDefs: this.columnTransitDefs,
    rowData: this.rowFutureData,
    animateRows: true,
    onGridReady: (params: any) => {
      this.futureGridApi = params.api;
    }
  }
  getStocks(storeId:any) {
    this.gridApi.showLoadingOverlay();
    this.stockService.getProductByStock(false, false, storeId).subscribe((resp) => {
      this.rowData = resp;
      this.initStockChart();
      this.gridApi.showLoadingOverlay();
    })
  }
  getFutureStocks(storeId:any) {
    this.futureGridApi.showLoadingOverlay();
    this.stockService.getProductByStock(false, true, storeId).subscribe((resp) => {
      this.rowFutureData = resp;
      this.initFutureStockChart();
      this.futureGridApi.showLoadingOverlay();
    })
  }
  productGraphInfo() {
    return this.rowData?.sort((a,b) => b.availableStock-a.availableStock)?.slice(0,5)?.map(x => {
      return { name: x.name, value: x.availableStock }
    });
  }

  futureProductGraphInfo() {
    return this.rowFutureData?.sort((a,b) => b.availableStock-a.availableStock)?.slice(0,5)?.map(x => {
      return { name: x.name, value: x.availableStock }
    });
  }
}
