import { Routes } from '@angular/router';
import { LoginComponent } from './feature/login/login.component';
import { AuthGuard } from './shared/guards/AuthGuard';
import { LayoutComponent } from './feature/layout/layout.component';
import { DashboardComponent } from './feature/dashboard/dashboard.component';
import { StockInComponent } from './feature/stock-in/stock-in.component';
import { StockOutComponent } from './feature/stock-out/stock-out.component';
import { AuditComponent } from './feature/audit/audit.component';
import { StockComponent } from './feature/stock/stock.component';
import { UserComponent } from './feature/user/user.component';
import { LevelNRackComponent } from './feature/level-nrack/levelnrack.component';
import { IpwhitelistComponent } from './feature/ipwhitelist/ipwhitelist.component';
import { StoresComponent } from './feature/stores/stores.component';
import { PurchaseReportComponent } from './feature/reports/purchase-report/purchase-report.component';
import { SalesReportComponent } from './feature/reports/sales-report/sales-report.component';
import { OrderComponent } from './feature/order/order.component';
import { CreateComponent } from './feature/order/create/create.component';
import { EditComponent } from './feature/order/edit/edit.component';

export const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { 
      path: '', 
      component: LayoutComponent, 
      children: [
        { path: '', component: DashboardComponent, canActivate: [AuthGuard] }
      ],
     
    },
    { 
      path: '', 
      component: LayoutComponent, 
      children: [
        { path: 'checkin', component: StockInComponent , canActivate: [AuthGuard]}
      ] 
    },
    { 
      path: '', 
      component: LayoutComponent, 
      children: [
        { path: 'checkout', component: StockOutComponent, canActivate: [AuthGuard] }
      ] 
    },
    { 
      path: '', 
      component: LayoutComponent, 
      children: [
        { path: 'audit', component: AuditComponent , canActivate: [AuthGuard]}
      ] 
    },
    { 
      path: '', 
      component: LayoutComponent, 
      children: [
        { path: 'stock', component: StockComponent , canActivate: [AuthGuard]}
      ] 
    }, { 
      path: '', 
      component: LayoutComponent, 
      children: [
        { path: 'user', component: UserComponent , canActivate: [AuthGuard]}
      ] 
    },
    { 
      path: '', 
      component: LayoutComponent, 
      children: [
        { path: 'racknlevel', component: LevelNRackComponent , canActivate: [AuthGuard]}
      ] 
    }
    , { 
      path: '', 
      component: LayoutComponent, 
      children: [
        { path: 'ip', component: IpwhitelistComponent , canActivate: [AuthGuard]}
      ] 
    },
    { 
      path: '', 
      component: LayoutComponent, 
      children: [
        { path: 'stores', component: StoresComponent , canActivate: [AuthGuard]}
      ] 
    },
    { 
      path: 'reports', 
      component: LayoutComponent, 
      children: [
        { path: 'purchase', component: PurchaseReportComponent , canActivate: [AuthGuard]}
      ] 
    },
    { 
      path: 'reports', 
      component: LayoutComponent, 
      children: [
        { path: 'sales', component: SalesReportComponent , canActivate: [AuthGuard]}
      ] 
    },
    { 
      path: '', 
      component: LayoutComponent, 
      children: [
        { path: 'order', component: OrderComponent , canActivate: [AuthGuard]}
      ] 
    },
    { 
      path: 'order', 
      component: LayoutComponent, 
      children: [
        { path: 'create', component: CreateComponent , canActivate: [AuthGuard]}
      ] 
    },
    { 
      path: 'order', 
      component: LayoutComponent, 
      children: [
        { path: 'edit/:id', component: EditComponent , canActivate: [AuthGuard]}
      ] 
    },
  ];

