<div class="card">
    <div class="card-header">
      <div class="row">
        <h1 class="h3 mb-2 text-gray-800">Edit Order</h1>
        <button *ngIf="rowData?.length && isOrderShipped()" class="btn btn-primary ml-auto" data-toggle="modal" data-target="#recievingModal" >Add Shipping Order </button>
        <button *ngIf="!rowData?.length && isOrderShipped()" class="btn btn-primary ml-auto" (click)="emptyAlert()" >Add Shipping Order </button>
      </div>
    </div>
    <div class="card-body" *ngIf="isloaded">
        <app-form-builder [form]="createOrder" (requestBodyEmit)="saveOrderConfirmation($event)" (selectedData)="selectedData($event)" (deleteRows)="deleteRows()" [isShowSaveButton]="isShowSaveButton" ></app-form-builder>
    </div>
    <div class="card-body" *ngIf="!isloaded">
        loading please wait...
    </div>
</div>

<div class="modal fade" id="recievingModal" tabindex="-1" role="dialog" role="dialog" aria-labelledby="myModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">

        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"> Order Recieving</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <ag-grid-angular  class="ag-theme-quartz" style="height: 500px;" [rowData]="rowData" [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef" [gridOptions]="gridOptions">
                </ag-grid-angular>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-dismiss="modal">Close</button>
                <button (click)="saveShippingOrder()" class="btn btn-primary" type="button" data-dismiss="modal"
                >Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" tabindex="-1" role="dialog" [ngClass]="{'show': isModalOpen}" [style.display]="isModalOpen ? 'block' : 'none'" aria-hidden="!isModalOpen"
    aria-hidden="true">
    <div class="modal-dialog modal-sm" role="document">

        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Confirmation</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Are you sure you want to change the record?</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-dismiss="modal" (click)="closeModal()" >Close</button>
                <button class="btn btn-primary" type="button" data-dismiss="modal"
                    (click)="saveOrder()">Update</button>
            </div>
        </div>
    </div>
</div>
<notifier-container></notifier-container>
<app-progress-loader [loading]="isLoading" ></app-progress-loader>
