import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { connectingString } from '../../contants';
import { ISales } from '../models/ISales';
import { IPurchase } from '../models/IPurchase';
import { IStore } from '../models/IStore';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  apiUrl: any = `${connectingString}Report/`;

  constructor(private http: HttpClient) { }

  getSales(fromDate:Date,toDate:Date,search:string,storeId:number): Observable<ISales[]> {
    return this.http.get<ISales[]>(`${this.apiUrl}sales?fromDate=${fromDate}&toDate=${toDate}&search=${search}&storeId=${storeId}`);
  }

  getPurchases(fromDate:Date,toDate:Date,search:string,storeId:number): Observable<IPurchase[]> {
    return this.http.get<IPurchase[]>(`${this.apiUrl}purchase?fromDate=${fromDate}&toDate=${toDate}&search=${search && ''}&storeId=${storeId}`);
  }
}
