import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { connectingString } from '../../contants';
import {  ILookupItem } from '../models/ILookup';

@Injectable({
    providedIn: 'root'
})
export class LookupService {
    apiUrl: any = `${connectingString}lookup`;

    constructor(private http: HttpClient) { }

    getLookup(lookupName:string): Observable<ILookupItem[]> {
        return this.http.get<ILookupItem[]>(`${this.apiUrl}/items?value=${lookupName}`);
    }

}