<ng-container
  *ngIf="notification.template; else predefinedNotification"
  [ngTemplateOutlet]="notification.template"
  [ngTemplateOutletContext]="{ notification: notification }"
>
</ng-container>

<ng-template #predefinedNotification>
  <p class="notifier__notification-message">{{ notification.message }}</p>
  <button
    class="notifier__notification-button"
    type="button"
    title="dismiss"
    *ngIf="config.behaviour.showDismissButton"
    (click)="onClickDismiss()"
  >
    <svg class="notifier__notification-button-icon" viewBox="0 0 24 24" width="20" height="20">
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
    </svg>
  </button>
</ng-template>
