import { Component } from '@angular/core';
import { AuthenticationService } from '../../../core/service/authentication.service';
import { ReportService } from '../../../core/service/report.service';
import { IPurchase } from '../../../core/models/IPurchase';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { NotifierModule, NotifierService } from 'angular-notifier';
import { ISales } from '../../../core/models/ISales';
import moment from 'moment';
import { UserStoreMapping } from '../../../core/models/IUserProfile';

@Component({
  selector: 'app-sales-report',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NotifierModule, AgGridAngular],
  templateUrl: './sales-report.component.html',
  styleUrl: './sales-report.component.css'
})
export class SalesReportComponent {
  detailGridApi: any;
  salesReportInformation: ISales[] = [];
  salesReportForm: FormGroup | undefined;
  constructor(private authService: AuthenticationService, private reportService: ReportService, private fb: FormBuilder, private notifier: NotifierService) { }
  userStoreMapping!: UserStoreMapping;
  ngOnInit(): void {
    this.authService.store$.subscribe(m => {
      this.userStoreMapping = m;
    });
    this.salesReportForm = this.fb.group({
      fromDate: ['', [Validators.required]],
      toDate: ['', [Validators.required]],
      search: []
    });
  }

  detailColumnDefs = [
    { headerName: 'Date', field: 'date',valueFormatter: this.dateFormatter, editable: false, width: 300 },
    { headerName: 'Name', field: 'name', editable: false },
    { headerName: 'Rack', field: 'rack', editable: false },
    { headerName: 'Level', field: 'level', editable: false },
    { headerName: 'Sold Quantity', field: 'soldQuantity', editable: false }
  ];

  gridOptionsDetail = {
    columnDefs: this.detailColumnDefs,
    rowData: this.salesReportInformation,
    animateRows: true,
    onGridReady: (params: any) => {
      this.detailGridApi = params.api;
    },
    sortable: true, filter: true
  }

  get fromDate() {
    return this.salesReportForm?.get('fromDate');
  }

  get toDate() {
    return this.salesReportForm?.get('toDate');
  }
  get search() {
    return this.salesReportForm?.get('search');
  }
  generateReport() {
    this.detailGridApi.showLoadingOverlay();
    let { fromDate, toDate, search } = this.salesReportForm?.value;
    this.reportService.getSales(fromDate, toDate, search, this.userStoreMapping.storeId).subscribe((purchaseResponse: ISales[]) => {
    
      if (purchaseResponse.length == 0) 
        this.notifier.notify('warning', 'No record found!');
      
    this.salesReportInformation = purchaseResponse;
    this.detailGridApi.showLoadingOverlay();
    });
  }

  dateFormatter(params: any) {
    return moment(params.value).format('DD-MM-YYYY');
  }

  print() {
    window.print();
  }
}
