import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { AuthenticationService } from '../../core/service/authentication.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink, RouterLinkActive],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.css'
})
export class LayoutComponent {
  userProfile: any;
  submenuVisible: boolean = false;
  selectedStore!: string | null;
  constructor(private authService: AuthenticationService, private router: Router) {
    this.loadUserProfile();
    if (localStorage.getItem('selectedStore')) {
      this.selectedStore = localStorage.getItem('selectedStore');
    }
  }

  toggleSubmenu() {
    this.submenuVisible = !this.submenuVisible;
  }
  logout() {

    this.authService.logout();
    this.router.navigate(['/login']);
  }

  loadUserProfile() {
    this.authService.getUserProfile().subscribe((resp) => {
      this.userProfile = resp;
      if (this.selectedStore) {
        this.authService._storeChanged.next(resp.userStoreMappings.filter(x => x.storeId == Number(this?.selectedStore) )[0])
      }
      else
        this.authService._storeChanged.next(resp.userStoreMappings[0]);
    })
  }
  storeChange(event: any) {
    const selectedStoreId = event.target.value; // Get the selected store's ID
    const selectedStoreMapping = this.userProfile?.userStoreMappings.find(
      storeMapping => storeMapping.storeId == selectedStoreId
    );
    this.authService._storeChanged.next(selectedStoreMapping);
    localStorage.setItem("selectedStore", selectedStoreId);
  }
  isShowTab(name: string) {
    var permission = localStorage.getItem('permission') ?? '[]';
    var jsonPermission = JSON.parse(permission);
    return jsonPermission.find((x: any) => `${x.permission.name}-${x.permission.module.name}` == `View-${name}`) != null;
  }

}
