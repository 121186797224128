import { OrderStatus } from "../../core/enum/order-status.enum";
import { IForm } from "../../core/models/IForm";

export const CreateOrderMeta: IForm[] = [
    {
        SectionName: "",
        SectionDetail: [

            {
                MappingName: "OrderRef",
                DisplayName: "Order Ref No",
                IsRequired: true,
                ValidationMessage: "Order ref no is required",
                InputType: "text",
                defaultValue: ""
            }
        ]
    },
    {
        SectionName: "",
        SectionDetail: [
            {
                MappingName: "orderStatusId",
                DisplayName: "Order Status",
                IsRequired: true,
                ValidationMessage: "Order status is required",
                InputType: "dropdown"
            },
            {
                MappingName: "VendorName",
                DisplayName: "Vender Name",
                IsRequired: true,
                ValidationMessage: "Vender name is required",
                InputType: "text"
            }
        ]
    },
    {
        SectionName: "",
        SectionDetail: [

            {
                MappingName: "ShipmentDate",
                DisplayName: "Expected Shipment Date",
                IsRequired: true,
                ValidationMessage: "Expected shipment date is required",
                InputType: "date"
            },
            {
                MappingName: "VendorAddress",
                DisplayName: "Vender Address",
                IsRequired: true,
                ValidationMessage: "Vender address is required",
                InputType: "text"
            }
        ]
    },
    {
        SectionName: "",
        SectionDetail: [

            {
                MappingName: "DeliveryDate",
                DisplayName: "Expected Delivery Date",
                IsRequired: true,
                ValidationMessage: "Expected delivery date is required",
                InputType: "date"
            },
            {
                MappingName: "ContactNo",
                DisplayName: "Contact No",
                IsRequired: true,
                ValidationMessage: "Contact no is required",
                InputType: "text"
            }
        ]
    },
    {
        SectionName: "",
        SectionDetail: [

            {
                MappingName: "remarks",
                DisplayName: "Remarks",
                IsRequired: false,
                ValidationMessage: "",
                InputType: "text"
            }
        ]
    },
    {
        SectionName: "",
        SectionDetail: [

            {
                MappingName: "orderDetails",
                DisplayName: "",
                isAllowManualDelete:true,
                ColumnDefinition: [
                    { field: "date", headerName: "Date" },
                    { field: "grade", headerName: "Grade" },
                    { field: "size", headerName: "Size" },
                    { field: "type", headerName: "Type" },
                    { field: "thread", headerName: "Thread" },
                    { field: "finish", headerName: "Finish" },
                    { field: "ctn", headerName: "CTN" },
                    { field: "quantity", headerName: "CTN Quantity" },
                    { field: "loose", headerName: "Loose Quantity" },
                ],
                Data: [],
                defaultValue: [],
                IsRequired: false,
                ValidationMessage: "",
                InputType: "grid"
            }
        ]
    }

]

export const UpdateOrderMeta: IForm[] = [
    {
        SectionName: "",
        SectionDetail: [


            {
                MappingName: "orderId",
                DisplayName: "Order Id",
                IsRequired: false,
                ValidationMessage: "",
                InputType: "text",
                defaultValue: "",
                IsReadOnly: true
            },
            {
                MappingName: "orderStatusId",
                DisplayName: "Order Status",
                IsRequired: true,
                ValidationMessage: "Order status is required",
                InputType: "dropdown",
                action: "submit"
            }
        ]
    },
    {
        SectionName: "",
        SectionDetail: [
            {
                MappingName: "OrderRef",
                DisplayName: "Order Ref No",
                IsRequired: true,
                ValidationMessage: "Order ref no is required",
                InputType: "text",
                defaultValue: "",
                IsReadOnly: true
            },
            {
                MappingName: "VendorName",
                DisplayName: "Vender Name",
                IsRequired: true,
                ValidationMessage: "Vender name is required",
                InputType: "text",
                IsReadOnly: true
            }
        ]
    },
    {
        SectionName: "",
        SectionDetail: [

            {
                MappingName: "ShipmentDate",
                DisplayName: "Expected Shipment Date",
                IsRequired: true,
                ValidationMessage: "Expected shipment date is required",
                InputType: "date",
                IsReadOnly: true
            },
            {
                MappingName: "VendorAddress",
                DisplayName: "Vender Address",
                IsRequired: true,
                ValidationMessage: "Vender address is required",
                InputType: "text",
                IsReadOnly: true
            }
        ]
    },
    {
        SectionName: "",
        SectionDetail: [

            {
                MappingName: "DeliveryDate",
                DisplayName: "Expected Delivery Date",
                IsRequired: true,
                ValidationMessage: "Expected delivery date is required",
                InputType: "date",
                IsReadOnly: true
            },
            {
                MappingName: "ContactNo",
                DisplayName: "Contact No",
                IsRequired: true,
                ValidationMessage: "Contact no is required",
                InputType: "text",
                IsReadOnly: true
            }
        ]
    },
    {
        SectionName: "",
        SectionDetail: [

            {
                MappingName: "remarks",
                DisplayName: "Remarks",
                IsRequired: false,
                ValidationMessage: "",
                InputType: "text",
                IsReadOnly: true
            }
        ]
    },
    
    {
        SectionName: "",
        SectionDetail: [

            {
                MappingName: "",
                DisplayName: "",
                Data: [],
                childern:[
                    {
                        SectionName: "Order Detail",
                        SectionDetail: [
                
                            {
                                MappingName: "orderDetails",
                                DisplayName: "",
                                isAllowManualDelete:true,
                                ColumnDefinition: [
                                    { field: "date", headerName: "Date" },
                                    { field: "grade", headerName: "Grade" },
                                    { field: "size", headerName: "Size" },
                                    { field: "type", headerName: "Type" },
                                    { field: "thread", headerName: "Thread" },
                                    { field: "finish", headerName: "Finish" },
                                    { field: "ctn", headerName: "CTN" },
                                    { field: "quantity", headerName: "CTN Quantity" },
                                    { field: "loose", headerName: "Loose Quantity" },
                                ],
                                Data: [],
                                defaultValue: [],
                                IsRequired: false,
                                IsReadOnly: true,
                                ValidationMessage: "",
                                InputType: "grid"
                            }
                        ]
                    },
                    {
                        SectionName: "Shipping Detail",
                        SectionDetail: [
                            {
                                MappingName: "orderDetails",
                                DisplayName: "",
                            
                                ColumnDefinition: [
                                    { field: "productName", headerName: "Product Name" },
                                    { field: "orderId", headerName: "Order Id" },
                                    { field: "date", headerName: "Date" },
                                    { field: "totalBox", headerName: "Total Box" },
                                    { field: "quantityPerBox", headerName: "Quantity Per Box" },
                                    { field: "quantityLoss", headerName: "Loose Quantity" }
                                ],
                                Data: [
                                   
                                  ],
                                defaultValue: [],
                                IsRequired: false,
                                IsReadOnly: true,
                                ValidationMessage: "",
                                InputType: "multi-grid"
                            }
                        ]
                    }
                ],
                defaultValue: [],
                IsRequired: false,
                ValidationMessage: "",
                InputType: "tab"
            }
        ]
    }

]

export const UpdateDraftOrderMeta: IForm[] = [
    {
        SectionName: "",
        SectionDetail: [
            {
                MappingName: "orderId",
                DisplayName: "Order Id",
                IsRequired: false,
                ValidationMessage: "",
                InputType: "text",
                defaultValue: "",
                IsReadOnly:true
            },
            {
                MappingName: "orderStatusId",
                DisplayName: "Order Status",
                IsRequired: true,
                ValidationMessage: "Order status is required",
                InputType: "dropdown"
            }
        ]
    },
    {
        SectionName: "",
        SectionDetail: [
            {
                MappingName: "OrderRef",
                DisplayName: "Order Ref No",
                IsRequired: true,
                ValidationMessage: "Order ref no is required",
                InputType: "text",
                defaultValue: ""
            },
            {
                MappingName: "VendorName",
                DisplayName: "Vender Name",
                IsRequired: true,
                ValidationMessage: "Vender name is required",
                InputType: "text"
            }
        ]
    },
    {
        SectionName: "",
        SectionDetail: [

            {
                MappingName: "ShipmentDate",
                DisplayName: "Expected Shipment Date",
                IsRequired: true,
                ValidationMessage: "Expected shipment date is required",
                InputType: "date"
            },
            {
                MappingName: "VendorAddress",
                DisplayName: "Vender Address",
                IsRequired: true,
                ValidationMessage: "Vender address is required",
                InputType: "text"
            }
        ]
    },
    {
        SectionName: "",
        SectionDetail: [

            {
                MappingName: "DeliveryDate",
                DisplayName: "Expected Delivery Date",
                IsRequired: true,
                ValidationMessage: "Expected delivery date is required",
                InputType: "date"
            },
            {
                MappingName: "ContactNo",
                DisplayName: "Contact No",
                IsRequired: true,
                ValidationMessage: "Contact no is required",
                InputType: "text"
            }
        ]
    },
    {
        SectionName: "",
        SectionDetail: [

            {
                MappingName: "remarks",
                DisplayName: "Remarks",
                IsRequired: false,
                ValidationMessage: "",
                InputType: "text"
            }
        ]
    },
    {
        SectionName: "",
        SectionDetail: [

            {
                MappingName: "orderDetails",
                DisplayName: "",
                isAllowManualDelete:true,
                ColumnDefinition: [
                    { field: "date", headerName: "Date"  },
                    { field: "grade", headerName: "Grade" },
                    { field: "size", headerName: "Size"  },
                    { field: "type", headerName: "Type"},
                    { field: "thread", headerName: "Thread" },
                    { field: "finish", headerName: "Finish" },
                    { field: "ctn", headerName: "CTN" },
                    { field: "quantity", headerName: "CTN Quantity" },
                    { field: "loose", headerName: "Loose Quantity" },
                ],
                Data: [],
                defaultValue: [],
                IsRequired: false,
                ValidationMessage: "",
                InputType: "grid"
            }
        ]
    },
   

]

export const UpdateConfirmationOrderMeta: IForm[] = [
    {
        SectionName: "",
        SectionDetail: [
            {
                MappingName: "orderId",
                DisplayName: "Order Id",
                IsRequired: false,
                ValidationMessage: "",
                InputType: "text",
                defaultValue: "",
                IsReadOnly:true
            },
            {
                MappingName: "orderStatusId",
                DisplayName: "Order Status",
                IsRequired: true,
                
                ValidationMessage: "Order status is required",
                InputType: "dropdown",
                action: "submit"
            }
        ]
    },
    {
        SectionName: "",
        SectionDetail: [
            {
                MappingName: "OrderRef",
                DisplayName: "Order Ref No",
                IsRequired: true,
                ValidationMessage: "Order ref no is required",
                InputType: "text",
                defaultValue: "",
                IsReadOnly:true
            },
            {
                MappingName: "VendorName",
                DisplayName: "Vender Name",
                IsRequired: true,
                ValidationMessage: "Vender name is required",
                InputType: "text",
                IsReadOnly:true
            }
        ]
    },
    {
        SectionName: "",
        SectionDetail: [

            {
                MappingName: "ShipmentDate",
                DisplayName: "Expected Shipment Date",
                IsRequired: true,
                ValidationMessage: "Expected shipment date is required",
                InputType: "date",
                IsReadOnly:true
            },
            {
                MappingName: "VendorAddress",
                DisplayName: "Vender Address",
                IsRequired: true,
                ValidationMessage: "Vender address is required",
                InputType: "text",
                IsReadOnly:true
            }
        ]
    },
    {
        SectionName: "",
        SectionDetail: [

            {
                MappingName: "DeliveryDate",
                DisplayName: "Expected Delivery Date",
                IsRequired: true,
                ValidationMessage: "Expected delivery date is required",
                InputType: "date",
                IsReadOnly:true
            },
            {
                MappingName: "ContactNo",
                DisplayName: "Contact No",
                IsRequired: true,
                ValidationMessage: "Contact no is required",
                InputType: "text",
                IsReadOnly:true
            }
        ]
    },
    {
        SectionName: "",
        SectionDetail: [

            {
                MappingName: "remarks",
                DisplayName: "Remarks",
                IsRequired: false,
                ValidationMessage: "",
                InputType: "text",
                IsReadOnly:true
            }
        ]
    },
    {
        SectionName: "",
        SectionDetail: [

            {
                MappingName: "orderDetails",
                DisplayName: "",
                isAllowManualDelete:false,
                ColumnDefinition: [
                    { field: "grade", headerName: "Grade" , editable : false },
                    { field: "size", headerName: "Size" , editable : false },
                    { field: "type", headerName: "Type", editable : false  },
                    { field: "thread", headerName: "Thread", editable : false  },
                    { field: "finish", headerName: "Finish", editable : false  },
                    { field: "ctn", headerName: "CTN" },
                    { field: "quantity", headerName: "CTN Quantity" },
                    { field: "loose", headerName: "Loose Quantity" },
                ],
                Data: [],
                defaultValue: [],
                IsRequired: false,
                ValidationMessage: "",
                InputType: "grid",
                IsReadOnly:true
            }
        ]
    },
   

]

