import { Component, ElementRef, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';
import { NotifierModule, NotifierService } from 'angular-notifier';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { ApproveButtonRendererComponentComponent } from '../../ag-grid/approve-button-renderer-component/approve-button-renderer-component.component';
import { SearchPipe } from '../../shared/pipe/SearchPipe';
import { StockService } from '../../core/service/stock.service';
import { AuthenticationService } from '../../core/service/authentication.service';
import { UserStoreMapping } from '../../core/models/IUserProfile';

@Component({
  selector: 'app-audit',
  standalone: true,
  imports: [AgGridAngular, FormsModule, NotifierModule],
  templateUrl: './audit.component.html',
  styleUrl: './audit.component.css'
})
export class AuditComponent {
  userStoreMapping!: UserStoreMapping
  constructor(private stockService: StockService,
    private authService: AuthenticationService,
    private notifier: NotifierService) {

  }
  ngOnInit(): void {
    this.authService.store$.subscribe(m => {
      this.userStoreMapping = m;
      this.getStocks(m.storeId, this.isFutureStock);
    });
  }
  @ViewChild('agGrid') agGrid!: AgGridAngular;
  searchText: string = "";
  DeleteIds: any[] = [];
  isFutureStock: boolean = false;
  columnDefs = [
    { headerName: '#', checkboxSelection: true, pinned: 'left', width: 50, sortable: false, filter: false },
    { headerName: 'Product Name', field: 'productName', editable: false, pinned: 'left', width: 300 },
    { headerName: 'SO Number', field: 'soNumber', editable: false },
    { headerName: 'Message', field: 'message', editable: false },
    { headerName: 'Rack', field: 'rack', editable: true, pinned: 'left', width: 100 },
    { headerName: 'Level', field: 'level', editable: true },
    { headerName: 'Stock Type', field: 'stockType', editable: false },
    { headerName: 'Date', field: 'date', valueFormatter: this.dateFormatter, editable: false },
    {
      headerName: 'Total Box', field: 'totalBox', editable: (params: any) => {
        if (params.data.stockType == "Sold")
          return false;
        return true;
      }
    },
    {
      headerName: 'Quantity Per Box', field: 'quantityPerBox', editable: true
    },

    {
      headerName: 'Quantity Loose', field: 'quantityLoss', editable: (params: any) => {
        if (params.data.stockType == "Sold")
          return false;
        return true;
      }
    },
    {
      headerName: 'Sold Quantity', field: 'soldQuantity', editable: true,
      cellStyle: (params: any) => {
        if (params.value < 0) {
          return { color: 'red' };
        }
        return null;
      }
    },
    {
      headerName: 'Total Stock', editable: false, valueGetter: (params: any) => {
        return params.data.soldQuantity < 0 ? params.data.soldQuantity : (params.data.totalBox * params.data.quantityPerBox) + params.data.quantityLoss
      },
      cellStyle: (params: any) => {
        if (params.value < 0) {
          return { color: 'red' };
        }
        return null;
      }
    },
    {
      headerName: 'Actions',
      colId: "action",
      pinned: 'right',
      hide: true,
      cellRenderer: ApproveButtonRendererComponentComponent,
      width: 150,
      cellRendererParams: {
        onApprove: this.onApprove.bind(this)
      }
    }
  ];

  onApprove(event: any) {
    this.stockService.updateFutureStatus(event.id, !this.isFutureStock).subscribe(() => {
      this.getStocks(this.userStoreMapping.storeId, this.isFutureStock);
      this.notifier.notify('success', 'Future Stock Updated Successfully!');
    });
    console.log(event);
  }
  exportToExcel() {
    // Define the columns for both Mapping (for data) and Display (for headers)
    const columns = [
      { Mapping: "productName", Display: "Product Name" },
      { Mapping: "soNumber", Display: "SO Number" },
      { Mapping: "message", Display: "Message" },
      { Mapping: "level", Display: "Level" },
      { Mapping: "stockType", Display: "Stock Type" },
      { Mapping: "date", Display: "Date" },
      { Mapping: "totalBox", Display: "Total Box" },
      { Mapping: "quantityPerBox", Display: "Quantity Per Box" },
      { Mapping: "quantityLoss", Display: "Loose Quantity" },
      { Mapping: "soldQuantity", Display: "Sold Quantity" },
      { Mapping: "TotalStock", Display: "Total Stock" }
    ];

    const header = columns.map(col => col.Display);

    const data = this.rowData.map((row: any) => {
      const mappedRow: any = {};

      columns.forEach(col => {
        if (col.Mapping === "TotalStock") {
          mappedRow[col.Display] = row.soldQuantity < 0
            ? row.soldQuantity
            : (row.totalBox * row.quantityPerBox) + row.quantityLoss;
        } else {
          mappedRow[col.Display] = row[col.Mapping];
        }
      });

      return mappedRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(data, { header: header });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    XLSX.writeFile(workbook, 'audit.xlsx');
  }

  getColumns(data: any[]): string[] {
    const columns: any[] = [];
    data.forEach(row => {
      Object.keys(row).forEach(col => {
        if (!columns.includes(col)) {
          // col == "quantityLoss"? col = "quantityLoose" : "";
          columns.push(col);
        }
      });
    });
    return columns;
  }

  dateFormatter(params: any) {
    return moment(params.value).format('DD-MM-YYYY');
  }
  rowData = [
  ];
  rowFilteredData: any[] = [];
  defaultColDef = {
    editable: true,
    sortable: true, filter: true
  };

  gridApi: any;

  gridOptions = {
    columnDefs: this.columnDefs,
    rowData: this.rowData,
    animateRows: true,
    onGridReady: (params: any) => {
      this.gridApi = params.api;
    },
    onSelectionChanged: this.onSelectionChanged.bind(this),
    rowSelection: 'multiple',
    onCellValueChanged: (event: any) => {

      if ((event.newValue === '' || event.newValue === null)) {
        event.node.setDataValue(event.colDef.field, event.oldValue);
      }
      this.stockService.updateStock(event.data).subscribe(() => {
        this.notifier.notify('success', 'Record Updated Successfully');
      })
    }
  };

  onSelectionChanged(event: any) {
    const selectedRows = event.api.getSelectedRows();
    let ids = [];
    for (let i = 0; i < selectedRows.length; i++) {
      ids.push(selectedRows[i].id);
    }
    this.DeleteIds = ids;
  }

  getStocks = (storeId: number, isShowFutureStock: boolean = false) => {
    this.gridApi.showLoadingOverlay();
    this.stockService.getStock(isShowFutureStock, storeId).subscribe((resp) => {
      this.rowData = resp;
      this.rowFilteredData = resp;
      this.gridApi.setColumnsVisible(['action'], this.isFutureStock)
      this.gridApi.hideOverlay();
    })
  }

  handleDataChange() {
    const searchPipe = new SearchPipe();
    this.rowFilteredData = searchPipe.transform(this.rowData, this.searchText, ['productName', 'rack', 'level', 'stockType', 'soNumber']);
  }
  DeleteRecords() {
    if (this.DeleteIds?.length == 0) {
      this.notifier.notify('warning', 'Please select record to delete');
      return;
    }
    this.searchText = "";
    this.stockService.deleteStock(this.DeleteIds).subscribe(() => {
      this.getStocks(this.userStoreMapping.storeId, this.isFutureStock);
      this.DeleteIds = [];
      this.notifier.notify('success', 'Stock deleted Successfully!');
    })
  }
  selectAll() {
    this.agGrid.api.selectAll();
  }
  showFutureStock() {

    this.getStocks(this.userStoreMapping.storeId, this.isFutureStock);

  }

}
