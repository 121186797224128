import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NotifierModule, NotifierService } from 'angular-notifier';
import { OrderService } from '../../core/service/order.service';
import { IOrder } from '../../core/models/IOrder';
import { RouterOutlet, RouterLink, RouterLinkActive, Router } from '@angular/router';
import { ActionButtonRendererComponentComponent } from '../../ag-grid/action-button-renderer-component/action-button-renderer-component.component';
import { AgGridAngular } from 'ag-grid-angular';
import { OrderStatus } from '../../core/enum/order-status.enum';
import moment from 'moment';
import { AuthenticationService } from '../../core/service/authentication.service';
import { UserStoreMapping } from '../../core/models/IUserProfile';

@Component({
  selector: 'app-order',
  standalone: true,
  imports: [AgGridAngular, FormsModule, NotifierModule, RouterOutlet, RouterLink, RouterLinkActive],
  templateUrl: './order.component.html',
  styleUrl: './order.component.css'
})
export class OrderComponent {

  constructor( private authService: AuthenticationService, private order: OrderService, private router: Router,private notifier: NotifierService) {

  }
  userStoreMapping!:UserStoreMapping;
  dateFrom: string = moment().add(-90,'days').format("YYYY-MM-DD");
  dateTo: string = new Date().toISOString().slice(0, 10);
  columnDefs = [
    { headerName: 'Order Id', field: 'id', editable: false },
    {
      headerName: 'Status', field: 'statusId',
      valueFormatter: (params: any) => {
        return OrderStatus[params.value];
      }
    },
    {
      headerName: 'Exp. Shipment Date', field: 'expectedShipmentDate', valueFormatter: (params: any) => {
        return moment(params.value).format("DD-MM-YYYY");
      }
    },
    {
      headerName: 'Exp. Delivery Date', field: 'expectedDeliveryDate', valueFormatter: (params: any) => {
        return moment(params.value).format("DD-MM-YYYY");
      }
    },
    { headerName: 'Vender', field: 'vendorName' },
    {
      headerName: 'Actions',
      cellRenderer: ActionButtonRendererComponentComponent,
      width: 150,
      cellRendererParams: {
        onDelete: this.onDelete.bind(this),
        onEdit: this.onEdit.bind(this),
      }
    },


  ];
  rowData: any[] = [];
  defaultColDef = {
    sortable: true, filter: true
  };

  gridApi: any;

  ngOnInit(): void {
    this.authService.store$.subscribe(m => {
      this.userStoreMapping = m;
      this.getAllOrders(m.storeId);
    });
  }

  gridOptions = {
    columnDefs: this.columnDefs,
    rowData: this.rowData,
    animateRows: true,
    onGridReady: (params: any) => {
      this.gridApi = params.api;
      
    }
  }

  onEdit(obj: any) {
    this.router.navigate([`/order/edit/${obj.id}`]);
  }

  onDelete(obj: any) {
    if(obj.statusId == OrderStatus.DRAFT)
      this.order.deleteOrder(obj.id).subscribe((res)=>{
        this.getAllOrders(this.userStoreMapping.storeId);
      });
    else
    this.notifier.notify('error', 'Order deletion not allowed!');
  }

  handleSearch() {
    this.getAllOrders(this.userStoreMapping.storeId);
  }

  getAllOrders(storeId:number) {
    this.order.getAllOrders(this.dateFrom, this.dateTo,storeId).subscribe((orders: IOrder[]) => {
      this.rowData = orders;

    });
  }
}
