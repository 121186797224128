import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { connectingString } from '../../contants';
import { Subject } from 'rxjs';
import { IUserProfile } from '../models/IUserProfile';
import { UserStoreMapping  } from '../models/IUserProfile';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  _storeChanged = new Subject<UserStoreMapping>();
  store$ = this._storeChanged.asObservable();

  constructor(private http: HttpClient) { }

  userLogin(userName: string, password: string,ip:string): Observable<any> {
    return this.http.get<any>(`${connectingString}User/login?userName=${userName}&password=${password}&ip=${ip}`);
  }
  getToken(): string | null {
    return localStorage.getItem('token'); // or wherever you store your token
  }
  logout(){
    localStorage.clear();
  }
  getUserProfile() : Observable<IUserProfile> {
    return this.http.get<any>(`${connectingString}User/UserProfile`);
  }
  getRole(): Observable<any> {
    return this.http.get<any>(`${connectingString}User/Role`);
  }
  saveUser(object:any): Observable<any> {
    return this.http.post(`${connectingString}User/AddUser`,object);
  }

  updateUser(object:any): Observable<any> {
    return this.http.put(`${connectingString}User/UpdateUser`,object);
  }

  getUser(): Observable<any> {
    return this.http.get(`${connectingString}User/Users`);
  }
  getIP(): Observable<any> {
    return this.http.get(`${connectingString}User/IP`);
  }
  deleteIP(id:number): Observable<any> {
    return this.http.delete(`${connectingString}User/DeleteIP?id=${id}`);
  }
  deleteUser(id:number): Observable<any> {
    return this.http.delete(`${connectingString}User/DeleteUser?userId=${id}`);
  }
  saveIP(object:any): Observable<any> {
    return this.http.post(`${connectingString}User/AddIp`,object);
  }
  getIPAddress(): Observable<any> {
    return this.http.get(`https://ipv4.myexternalip.com/json`);
  }
}
