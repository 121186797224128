import { Component, HostListener } from '@angular/core';
import { IForm } from '../../../core/models/IForm';
import { UpdateConfirmationOrderMeta, UpdateDraftOrderMeta, UpdateOrderMeta } from '../meta';
import { FormBuilderComponent } from '../../shared-component/form-builder/form-builder.component';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { OrderService } from '../../../core/service/order.service';
import { IOrderDetail, IOrderResponse } from '../../../core/models/IOrder';
import { CommonModule } from '@angular/common';
import { LookupService } from '../../../core/service/lookup.service';
import { ILookupItem } from '../../../core/models/ILookup';
import { OrderLookup } from '../../../contants';
import moment from 'moment';
import { AuthenticationService } from '../../../core/service/authentication.service';
import { UserStoreMapping } from '../../../core/models/IUserProfile';
import { AgGridAngular } from 'ag-grid-angular';
import { OrderStatus } from '../../../core/enum/order-status.enum';
import { NotifierModule, NotifierService } from 'angular-notifier';
import { ProgressLoaderComponent } from '../../../shared/progress-loader/progress-loader.component';


@Component({
  selector: 'app-edit',
  standalone: true,
  imports: [NotifierModule,ProgressLoaderComponent,AgGridAngular, FormBuilderComponent, CommonModule, RouterLink, RouterLinkActive],
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.css'
})
export class EditComponent {
  isLoading:boolean = false;
  isloaded: boolean = false;
  userStoreMapping!: UserStoreMapping;
  columnDefs =
    [
      { field: "grade", headerName: "Grade" },
      { field: "size", headerName: "Size" },
      { field: "type", headerName: "Type" },
      { field: "thread", headerName: "Thread" },
      { field: "finish", headerName: "Finish" },
      { field: "ctn", headerName: "CTN", editable: true },
      { field: "quantity", headerName: "CTN Quantity", editable: true },
      { field: "loose", headerName: "Loose Quantity", editable: true },
    ];
  rowData: any[] = [];
  orderStatus: number = 0;
  defaultColDef = {
    sortable: true, filter: true
  };
  gridApi: any;
  orderId: number = 0;
  isShowSaveButton: boolean = false;
  createOrder: IForm[] = [];
  gridOptions = {
    columnDefs: this.columnDefs,
    rowData: this.rowData,
    sortable: true,
    filter: true,
    editable: true,

    animateRows: true,
    onGridReady: (params: any) => {
      this.gridApi = params.api;
    }
  }

  isModalOpen = false;

  constructor(private notifier: NotifierService,private lookupService: LookupService, private orderService: OrderService, private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService
  ) {

  }
  ngOnInit() {

    this.route.params.subscribe(params => {

      this.getOrder(params['id']);

    });

    this.authService.store$.subscribe(m => {
      this.userStoreMapping = m;
    });

  }
 getOrderDetail(orderId:number){
  this.orderService.getOrderDetail(orderId).subscribe((orderDetail)=>{
    if(!this.isOrderDraft() && !this.isOrderConfirmed())
    this.createOrder[5].SectionDetail[0].childern[1].SectionDetail[0].Data = this.transformData(orderDetail);

  })
 }

 transformData(data: any[]): any[] {
  return data.map(item => {
    return {
      data: item,  // The main order data
      children: item.shippingDetail.map((shipping:any) => ({
        data: shipping // The child shipping detail data
      }))
    };
  });
}

  openModal() {
    this.isModalOpen = true;
  }

  refreshView(){
    this.router.navigateByUrl(`/`, { skipLocationChange: true }).then(() =>
      this.router.navigate([`/order/edit/${this.orderId}`]));
  }

  closeModal() {
    this.refreshView();
    this.isModalOpen = false;
  }

  getOrderStatusList() {
    this.lookupService.getLookup(OrderLookup).subscribe((status: ILookupItem[]) => {
      this.createOrder[0].SectionDetail[1].Data = status

    });
  }
  isOrderShipped() {
    debugger
    return this.orderStatus == OrderStatus.SHIPPED;
  }
  isOrderDraft() {
    return this.orderStatus == OrderStatus.DRAFT;
  }

  isOrderConfirmed() {
    return this.orderStatus == OrderStatus.CONFIRMED;
  }

  getOrder(id: string) {
    this.orderService.getOrderById(id).subscribe((order: IOrderResponse) => {

      this.orderId = order.orderId;
      this.orderStatus = order.orderStatusId;
      if (order.orderStatusId == OrderStatus.DRAFT) {
        this.isShowSaveButton = true;
        this.createOrder = UpdateDraftOrderMeta;
      } else if(order.orderStatusId == OrderStatus.CONFIRMED)
        {
          this.createOrder = UpdateConfirmationOrderMeta
        }
       else {
        this.isShowSaveButton = false;
        this.createOrder = UpdateOrderMeta;
      }
      this.getOrderStatusList();
      this.getOrderDetail(this.orderId)
      this.renderInitObject(order);
      this.isloaded = true;
    })
  }

  renderInitObject(order: IOrderResponse) {
    this.createOrder[1].SectionDetail[0].defaultValue = order.orderRef;
    this.createOrder[0].SectionDetail[0].defaultValue = order.orderId;
    this.createOrder[0].SectionDetail[1].defaultValue = order.orderStatusId;
    this.createOrder[1].SectionDetail[1].defaultValue = order.vendorName;
    this.createOrder[2].SectionDetail[0].defaultValue = moment(order.expectedShipmentDate).format("YYYY-MM-DD");
    this.createOrder[2].SectionDetail[1].defaultValue = order.vendorAddress;
    this.createOrder[3].SectionDetail[0].defaultValue = moment(order.expectedDeliveryDate).format("YYYY-MM-DD");
    this.createOrder[3].SectionDetail[1].defaultValue = order.contactNo;
    this.createOrder[4].SectionDetail[0].defaultValue = order.remarks;
    if(this.isOrderDraft() || this.isOrderConfirmed())
    this.createOrder[5].SectionDetail[0].Data = this.getDetailData(order.orderDetails);
  else
  this.createOrder[5].SectionDetail[0].childern[0].SectionDetail[0].Data = this.getDetailData(order.orderDetails);
 
  }

  getDetailData(detail: IOrderDetail[]) {

    return detail.map((item) => {
      item.loose = `${item.loose}`;
      item.ctn = `${item.ctn}`;
      item.quantity = `${item.quantity}`;
      return item;
    });
  }
  requestContent: any;
  saveOrderConfirmation(requestBody: any) {
    this.requestContent = requestBody;
    console.log(requestBody);
    if(requestBody.orderStatusId == OrderStatus.DRAFT)
      this.saveOrder();
    else
      this.openModal();
  }

  selectedData(response: any) {
    debugger
    this.rowData = response;
  }

  saveOrder() {
    this.isLoading=true;
    let requestBody = this.requestContent;
    requestBody.storeId = this.userStoreMapping.storeId;
    debugger
    requestBody.orderDetails = this.addNamesToInventory(requestBody.orderDetails)
    this.orderService.updateOrder(requestBody).subscribe((resp) => {
      this.isLoading=false;
      this.refreshView();
    });
  }

  saveShippingOrder() {
    this.isLoading=true;
    let requestBody: any = [];
    this.gridApi.forEachNode((node: any) => {
      let data = node.data;
      data.orderId = this.orderId;
      requestBody.push(data)
    });

    this.orderService.updateShipingOrder(requestBody).subscribe((res) => {
      this.isLoading = false;
      this.refreshView();
    })
  }

  @HostListener('document:paste', ['$event'])
  async onPaste(event: ClipboardEvent) {
    if (!this.isOrderDraft())
      return;
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const pastedData = clipboardData.getData('text/plain');
      this.parseData(pastedData);
    }
  }

  parseData(pastedData: string) {
    
    var headers = this.createOrder[5].SectionDetail[0].ColumnDefinition?.map((col) => col.field)?.filter(item => item != undefined);

    const rows = pastedData.split('\n').map(row => row.trim()).filter(row => row);
    var pastedJson = rows.map((row) => {
      const columns = row.split('\t').map(column => column.trim());

      return headers?.reduce((acc, header, index) => {
        acc[header] = columns[index] || '';

        return acc;
      }, {});
    });

    this.createOrder[5].SectionDetail[0].Data = this.createOrder[5].SectionDetail[0].Data?.concat(pastedJson);
  }


  deleteRows(){
    
    this.createOrder[5].SectionDetail[0].Data = this.createOrder[5].SectionDetail[0].Data?.filter(x =>
      !this.rowData.some(item => item.name === x.name)
    );
  }

  addNamesToInventory(inventory: any) {
    debugger
    return inventory.map((item: any) => {
      let name = '';

      if (item.thread?.toLowerCase() === 'ft' || item.thread?.toLowerCase() === 'ht') {
        name = `${item.size}-${item.grade}-${item.finish}-${item.type}-${item.thread}`;
      } else {
        name = `${item.size}-${item.grade}-${item.finish}-${item.type}`;
      }
      name = name.replace("HEX BOLT", "BOLT");

      return {
        ...item,
        name: this.modifyString(name)
      };
    });
  }

  emptyAlert(){
    this.notifier.notify('warning', 'Please select stock first!');
  }

  modifyString(input: string) {
    if (input.startsWith('-')) {
      input = input.slice(1);
    }
    if (input.endsWith('-')) {
      input = input.slice(0, -1);
    }
    input = input.replace(/--/g, '-');

    return input.trim();
  }

}
