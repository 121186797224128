import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { connectingString } from '../../contants';
import { IStore } from '../models/IStore';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  apiUrl: any = `${connectingString}Store/`;

  constructor(private http: HttpClient) { }
  
  getAllStores(): Observable<IStore[]> {
    return this.http.get<IStore[]>(`${this.apiUrl}getall`);
  }

   // Get store by ID
   getStoreById(storeId: number): Observable<IStore> {
    return this.http.get<IStore>(`${this.apiUrl}get?storeId=${storeId}`);
  }

  // Add a new store
  addStore(store: Partial<IStore>): Observable<Partial<IStore>> {
    return this.http.post<IStore>(`${this.apiUrl}add`, store);
  }

   // Update an existing store
   updateStore(store: Partial<IStore>): Observable<Partial<IStore>> {
    return this.http.put<IStore>(`${this.apiUrl}update`, store);
  }

  // Delete a store by ID
  deleteStore(storeId: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}delete?storeId=${storeId}`);
  }
}
