import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { connectingString } from '../../contants';
import { IStore } from '../models/IStore';
import { IOrder, IOrderResponse } from '../models/IOrder';

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    apiUrl: any = `${connectingString}`;

    constructor(private http: HttpClient) { }

    getAllOrders(fromDate:string,toDate:string,storeId:number): Observable<IOrder[]> {
        return this.http.get<IOrder[]>(`${this.apiUrl}orders?fromDate=${fromDate}&toDate=${toDate}&storeId=${storeId}`);
    }

    getOrderById(orderId:string): Observable<IOrderResponse> {
        return this.http.get<IOrderResponse>(`${this.apiUrl}order-by-id?orderId=${orderId}`);
    }

    createOrder(orderBody:any): Observable<any> {
        return this.http.post<any>(`${this.apiUrl}create-order`,orderBody);
    }
    updateOrder(orderBody:any): Observable<any> {
        return this.http.patch<any>(`${this.apiUrl}update-draft-order`,orderBody);
    }

    updateShipingOrder(orderBody:any): Observable<any> {
        return this.http.post<any>(`${this.apiUrl}update-shipping-order`,orderBody);
    }

    deleteOrder(orderId:number): Observable<any> {
        return this.http.delete<any>(`${this.apiUrl}order-delete?orderId=${orderId}`);
    }

    getOrderDetail(orderId:number): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}order-details?orderId=${orderId}`);
    }
}