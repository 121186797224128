<form (ngSubmit)="submit()" [formGroup]="formGroup">
    <div *ngFor="let section of form">
        <div class="form-row">
            <div *ngFor="let detail of section.SectionDetail" class="col">
                <label>{{detail.DisplayName}}</label>
                <input [readonly]="detail.IsReadOnly" [formControlName]="detail.MappingName" class="form-control"
                    [placeholder]="detail.DisplayName" *ngIf="detail.InputType == 'text'" type="text" />
                <input [readonly]="detail.IsReadOnly" [formControlName]="detail.MappingName" class="form-control"
                    [placeholder]="detail.DisplayName" *ngIf="detail.InputType == 'date'" type="date" />
                <select (change)="submitOnChange()" [readonly]="detail.IsReadOnly"
                    [formControlName]="detail.MappingName" class="form-control"
                    *ngIf="detail.InputType == 'dropdown' && detail.action=='submit'">
                    <option *ngFor="let item of detail.Data" [value]="item.id">{{item.value}}</option>
                </select>
                <select [readonly]="detail.IsReadOnly" [formControlName]="detail.MappingName" class="form-control"
                    *ngIf="detail.InputType == 'dropdown' && detail.action !='submit'">
                    <option *ngFor="let item of detail.Data" [value]="item.id">{{item.value}}</option>
                </select>
                <div *ngIf="detail.InputType == 'grid' && detail.isAllowManualDelete">
                    <button type="button" class="btn btn-primary btn-user btn-block m-1 col-2 ml-auto"
                        (click)="delete()">Delete</button>
                </div>
                <ag-grid-angular *ngIf="detail.InputType == 'grid'" style="height: 300px;" [rowSelection]="rowSelection"
                    [rowData]="detail.Data" [columnDefs]="detail.ColumnDefinition" class="ag-theme-quartz"
                    [defaultColDef]="detail.IsReadOnly ? '' : defaultColDef"
                    [gridOptions]="detail.IsReadOnly ? '' : gridOptions" (gridReady)="onGridReady($event)"
                    (selectionChanged)="onSelectionChanged()" />


                <p-tabs [value]="0" *ngIf="detail.InputType == 'tab'">
                    <p-tablist>
                        <p-tab *ngFor="let tab of detail.childern; let i = index"
                            [value]="i">{{tab.SectionName}}</p-tab>
                    </p-tablist>
                    <p-tabpanels>
                        <p-tabpanel *ngFor="let tab of detail.childern; let i = index" [value]="i">
                            <div *ngFor="let tabDetail of tab.SectionDetail;">
                                <ag-grid-angular *ngIf="tabDetail.InputType == 'grid'" [rowSelection]="rowSelection"
                                    style="height: 300px;" [rowData]="tabDetail.Data"
                                    [columnDefs]="tabDetail.ColumnDefinition" class="ag-theme-quartz"
                                    [defaultColDef]="tabDetail.IsReadOnly ? '' : defaultColDef"
                                    [gridOptions]="tabDetail.IsReadOnly ? '' : gridOptions"
                                    (gridReady)="onGridReady($event)" (selectionChanged)="onSelectionChanged()" />
                                    <p-treeTable *ngIf="tabDetail.InputType == 'multi-grid'" [value]="tabDetail.Data">
                                        <ng-template pTemplate="header">
                                          <tr>
                                            <th *ngFor="let col of tabDetail.ColumnDefinition">
                                              {{ col.headerName }}
                                            </th>
                                          </tr>
                                        </ng-template>
                                      
                                        <ng-template pTemplate="body" let-data>
                                          <tr>
                                            <td *ngFor="let col of tabDetail.ColumnDefinition; let rowIndex = index;">
                                              <span *ngIf="data.node.children && data.node.children.length > 0 && rowIndex === 0" 
                                                    (click)="toggleNode(data.node)">
                                                <i class="fa" [ngClass]="{
                                                      'fa-chevron-down': expandedNodes.includes(data.node), 
                                                      'fa-chevron-right': !expandedNodes.includes(data.node)
                                                  }"></i>
                                              </span>
                                              {{ data.node.data[col.field] ?? "" }}
                                            </td>
                                          </tr>
                                      
                                          <!-- Render child nodes only if the current node is expanded -->
                                          <ng-container *ngIf="data.node.children && data.node.children.length > 0 && expandedNodes.includes(data.node)">
                                            <tr *ngFor="let child of data.node.children">
                                              <td *ngFor="let col of tabDetail.ColumnDefinition">
                                                {{ child.data[col.field] ?? "" }}
                                              </td>
                                            </tr>
                                          </ng-container>
                                        </ng-template>
                                      </p-treeTable>
                                      
                            </div>

                        </p-tabpanel>

                    </p-tabpanels>
                </p-tabs>

                <div class="text-danger"
                    *ngIf="formGroup?.get(detail.MappingName).invalid && (formGroup?.get(detail.MappingName).dirty || formGroup?.get(detail.MappingName).touched)">
                    <div *ngIf="formGroup?.get(detail.MappingName).errors?.required">{{detail.ValidationMessage}}</div>
                </div>

            </div>
        </div>
    </div>
    <button type="submit" *ngIf="isShowSaveButton" [disabled]="formGroup.invalid"
        class="btn btn-primary btn-user btn-block m-1 col-2 ml-auto">Save</button>
</form>