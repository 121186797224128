<div class="card salesReport">
    <div class="card-header">
        <h1 class="h3 mb-2 text-gray-800">Sales Report</h1>
    </div>
    <div class="card-body">
        <form  (ngSubmit)="generateReport()" [formGroup]="salesReportForm">
            <div class="form-row">
                <div class="col">
                    <label>Date(From)</label>
                    <input required type="date" formControlName="fromDate" class="form-control" autocomplete="off">
                    <div *ngIf="fromDate.invalid && (fromDate.dirty || fromDate.touched)">
                        <div *ngIf="fromDate.errors?.required">Date(from) is required.</div>
                    </div>
                </div>
                <div class="col">
                    <label>Date(To)</label>
                    <input required type="date" formControlName="toDate" class="form-control" autocomplete="off">
                    <div *ngIf="toDate.invalid && (toDate.dirty || toDate.touched)">
                        <div *ngIf="toDate.errors?.required">Date(to) is required.</div>
                    </div>
                </div>
                <div class="col">
                        <label>Search</label>
                        <input type="text" formControlName="search" class="form-control" autocomplete="off">
                </div>
            </div>
            <div class="text-right mt-2">
                <button type="submit" [disabled]="salesReportForm.invalid" class="btn btn-primary btn-user col-2 mx-1">
                    Search
                </button>
                <button type="button" [disabled]="!salesReportInformation?.length" (click)="print()"  class="btn btn-primary btn-user col-2">
                    Print
                </button>
           
              
            </div>
        </form>
        <ag-grid-angular class="ag-theme-quartz mt-2" style="height: 500px;" [rowData]="salesReportInformation"
        [columnDefs]="detailColumnDefs" [defaultColDef]="gridOptionsDetail" [gridOptions]="gridOptionsDetail">
    </ag-grid-angular>
    </div>
</div>

<div class="table-container">
            
    <table class="table table-bordered" id="table-to-print">
      <thead>
        <tr>
          <th>Date</th>
          <th>Name</th>
          <th>Rack</th>
          <th>Level</th>
          <th>Sold Quantity</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of salesReportInformation">
          <td>{{ row.date | date }}</td>
          <td>{{ row.name }}</td>
          <td>{{ row.rack }}</td>
          <td>{{ row.level }}</td>
          <td>{{ row.soldQuantity }}</td>
        </tr>
      </tbody>
    </table>
</div>
<notifier-container></notifier-container>