import { CommonModule, formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { NotifierModule, NotifierService } from 'angular-notifier';
import moment from 'moment';
import { ActionDeleteButtonRendererComponentComponent } from '../../ag-grid/action-delete-button-renderer-component/action-delete-button-renderer-component';
import { AuthenticationService } from '../../core/service/authentication.service';

@Component({
  selector: 'app-ipwhitelist',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NotifierModule, AgGridAngular],
  templateUrl: './ipwhitelist.component.html',
  styleUrl: './ipwhitelist.component.css'
})
export class IpwhitelistComponent {

  roles: any[] = [];
  ipForm: FormGroup | undefined;
  gridApi: any;
  colDefs: any[] =
    [
      { field: "ipAddress", headerName: "Ip Address" },
      { field: "remarks", headerName: "Remarks" , width:400 },
      {
        headerName: 'Actions',
        cellRenderer: ActionDeleteButtonRendererComponentComponent,
        width: 150,
        cellRendererParams: {
          onDelete: this.delete.bind(this)
        }
      },
    ]
  rowData: any[] = [];
  ngOnInit() {
    this.ipForm = this.fb.group({
      id: [0, []],
      ipAddress: ['', [Validators.required]],
      remarks:['', [Validators.required]]
    });
  }

  gridOptions = {
    columnDefs: this.colDefs,
    rowData: this.rowData,
    animateRows: true,
    onGridReady: (params: any) => {
      this.gridApi = params.api;
      this.getIP();
    },
    sortable: true, filter: true
  }

  delete(ip: any) {
    this.authService.deleteIP(ip.id).subscribe(() => {
      this.getIP();
      this.notifier.notify('success', 'IP deleted Successfully!');
    })
  }

  get ipAddress() {
    return this.ipForm?.get('ipAddress');
  }
  get remarks() {
    return this.ipForm?.get('remarks');
  }

  constructor(private authService: AuthenticationService, private fb: FormBuilder, private notifier: NotifierService) {

  }
  SaveUser() {
    if (this.ipForm?.valid) {
        this.authService.saveIP(this.ipForm.value).subscribe((resp: any) => {
          this.notifier.notify('success', 'IP created successfully!');
          this.ipForm?.reset();
          this.getIP();
        }, () => {
          this.notifier.notify('error', 'IP Creation failed!');
        })
    }
  }

  getIP() {
    this.gridApi.showLoadingOverlay();
    this.authService.getIP().subscribe((resp: any) => {
      this.rowData = resp;
      this.gridApi.showLoadingOverlay();
    })
  }
}
