import { Component } from '@angular/core';
import { ActionButtonRendererComponentComponent } from '../../ag-grid/action-button-renderer-component/action-button-renderer-component.component';
import moment from 'moment';
import { StoreService } from '../../core/service/store.services';
import { AgGridAngular } from 'ag-grid-angular';
import { NotifierModule, NotifierService } from 'angular-notifier';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule, formatDate } from '@angular/common';
import { IStore } from '../../core/models/IStore';


@Component({
  selector: 'app-stores',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NotifierModule, AgGridAngular],
  templateUrl: './stores.component.html',
  styleUrl: './stores.component.css'
})
export class StoresComponent {
  roles: any[] = [];
  storeForm: FormGroup | undefined;

  colDefs: any[] =
    [
      { field: "name", headerName: "Name" },
      { field: "remarks", headerName: "Remarks" },
      { field: "createdOn", headerName: "Expiry Date" , valueFormatter: this.dateFormatter},
      {
        headerName: 'Actions',
        cellRenderer: ActionButtonRendererComponentComponent,
        width: 150,
        cellRendererParams: {
          onDelete: this.onDelete.bind(this),
          onEdit: this.onEdit.bind(this),
        }
      },
    ]
  rowData: any[] = [];
  ngOnInit() {
    this.storeForm = this.fb.group({
      id: [0, []],
      name: ['', [Validators.required]],
      remarks: ['', []],
      attr1:['',[Validators.required]],
      attr2:['',[Validators.required]],
      attr3:['',[Validators.required]],
      attr4:['',[Validators.required]],
      attr5:['',[Validators.required]],
    });
  }

  dateFormatter(params: any) {
    return moment(params.value).format('DD-MM-YYYY');
  }

  onDelete(store: any) {
    this.storeService.deleteStore(store.id).subscribe(() => {
      this.getStores();
      this.notifier.notify('success', 'Store deleted sucessfully!');
    })
  }
  isEdit: boolean = false;
  onEdit(store: IStore) {
    var requestBody = store;
    this.isEdit = true;
    // this.storeForm?.setValue(requestBody);

    this.storeForm.setValue({
      id: store.id,
      name: store.name,
      remarks: store.remarks,
      attr1: store?.lookup?.lookupItems?.filter(x=>x.extra === 'attr1')[0]?.value ?? null,
      attr2: store?.lookup?.lookupItems?.filter(x=>x.extra === 'attr2')[0]?.value ?? null,
      attr3: store?.lookup?.lookupItems?.filter(x=>x.extra === 'attr3')[0]?.value ?? null,
      attr4: store?.lookup?.lookupItems?.filter(x=>x.extra === 'attr4')[0]?.value ?? null,
      attr5: store?.lookup?.lookupItems?.filter(x=>x.extra === 'attr5')[0]?.value ?? null
    });
  }

  get name() {
    return this.storeForm?.get('name');
  }

  get remarks() {
    return this.storeForm?.get('remarks');
  }

  get attr1() {
    return this.storeForm?.get('attr1');
  }

  get attr2() {
    return this.storeForm?.get('attr2');
  }

  get attr3() {
    return this.storeForm?.get('attr3');
  }

  get attr4() {
    return this.storeForm?.get('attr4');
  }

  get attr5() {
    return this.storeForm?.get('attr5');
  }
  gridApi: any;

  constructor(private storeService: StoreService, private fb: FormBuilder, private notifier: NotifierService) {
  
  }

  gridOptions = {
    columnDefs: this.colDefs,
    rowData: this.rowData,
    animateRows: true,
    onGridReady: (params: any) => {
      this.gridApi = params.api;
      this.getStores();
    },
    sortable: true, 
    filter: true
  }

  SaveStore() {
    if (this.storeForm?.valid) {
      var body = this.SetStoreData(this.storeForm.value);
      if (this.isEdit) {
        this.storeService.updateStore(body).subscribe((resp: any) => {
          this.notifier.notify('success', 'Store updated successfully!');
          this.storeForm?.reset();
          this.getStores();
          this.isEdit = false;
        }, () => {
          this.notifier.notify('error', 'Store update failed!');
        })
      } else {
        this.storeService.addStore(body).subscribe((resp: any) => {
          this.notifier.notify('success', 'Store created successfully!');
          this.storeForm?.reset();
          this.getStores();
        }, () => {
          this.notifier.notify('error', 'Store already exist!');
        })
      }

    }
  }
  SetStoreData(inputData:any){
    return {
      id: inputData.id,
      name: inputData.name,
      remarks: inputData.remarks,
      lookup: {
        name: "Configuration_"+inputData.name,
        lookupItems: [
          {
            value: inputData.attr1,
            extra: "attr1",
          },
          {
            value: inputData.attr2,
            extra: "attr2"
          },
          {
            value: inputData.attr3,
            extra: "attr3"
          },
          {
            value: inputData.attr4,
            extra: "attr4"
          },
          {
            value: inputData.attr5,
            extra: "attr5"
          }
        ]
      }
    };
  }
  getStores() {
    this.gridApi.showLoadingOverlay();
    this.storeService.getAllStores().subscribe((resp: any) => {
      this.rowData = resp;
      this.gridApi.showLoadingOverlay();
    })
  }
}
