<div class="card">
    <div class="card-header">
        <h1 class="h3 mb-2 text-gray-800">Order</h1>
    </div>
    <div class="card-body">
        <div class="input-group mb-3">
            <div class="col-4">
                Date From <input type="date" [(ngModel)]="dateFrom" class="form-control" (change)="handleSearch()" />
            </div>
            <div class="col-4">
                Date To  <input type="date" [(ngModel)]="dateTo" class="form-control" (change)="handleSearch()" />
            </div>
            <div class="ml-auto">
                <a class="btn btn-primary" routerLink="/order/create" >Create Order</a>
            </div>
        </div>
        <ag-grid-angular class="ag-theme-quartz" style="height: 500px;" [rowData]="rowData" [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef" [gridOptions]="gridOptions">
        </ag-grid-angular>

    </div>
</div>
<notifier-container></notifier-container>