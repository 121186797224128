<div class="card">
    <div class="card-header">
        <h1 class="h3 mb-2 text-gray-800">Stores</h1>
    </div>
    <div class="card-body">
        <form (ngSubmit)="SaveStore()" [formGroup]="storeForm" >
            <div class="form-row">
                <div class="col">
                    <label>Name</label>
                    <input required type="text" formControlName="name" class="form-control" placeholder="Name" autocomplete="off">
                    <div *ngIf="name.invalid && (name.dirty || name.touched)">
                        <div *ngIf="name.errors?.required">Name is required.</div>
                    </div>
                </div>
                <div class="col">
                    <label>Remarks</label>
                    <input required type="text" formControlName="remarks" class="form-control" placeholder="Remarks" autocomplete="off">
                    <div *ngIf="remarks.invalid && (remarks.dirty || remarks.touched)">
                        <div *ngIf="remarks.errors?.required">Remarks is required.</div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col">
                    <label>Attr 1 (Grade)</label>
                    <input required type="text" formControlName="attr1" class="form-control" placeholder="Attr 1" autocomplete="off">
                    <div *ngIf="attr1.invalid && (attr1.dirty || attr1.touched)">
                        <div *ngIf="attr1.errors?.required">Attr 1 is required.</div>
                    </div>
                </div>
                <div class="col">
                    <label>Attr 2 (Type)</label>
                    <input required type="text" formControlName="attr2" class="form-control" placeholder="Attr 2" autocomplete="off">
                    <div *ngIf="attr2.invalid && (attr2.dirty || attr2.touched)">
                        <div *ngIf="attr2.errors?.required">Attr 2 is required.</div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col">
                    <label>Attr 3 (Size)</label>
                    <input required type="text" formControlName="attr3" class="form-control" placeholder="Attr 3" autocomplete="off">
                    <div *ngIf="attr3.invalid && (attr3.dirty || attr3.touched)">
                        <div *ngIf="attr3.errors?.required">Attr 3 is required.</div>
                    </div>
                </div>
                <div class="col">
                    <label>Attr 4 (Finish)</label>
                    <input required type="text" formControlName="attr4" class="form-control" placeholder="Attr 4" autocomplete="off">
                    <div *ngIf="attr4.invalid && (attr4.dirty || attr4.touched)">
                        <div *ngIf="attr4.errors?.required">Attr 4 is required.</div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col">
                    <label>Attr 5 (Thread)</label>
                    <input required type="text" formControlName="attr5" class="form-control" placeholder="Attr 5" autocomplete="off">
                    <div *ngIf="attr5.invalid && (attr5.dirty || attr5.touched)">
                        <div *ngIf="attr5.errors?.required">Attr 5 is required.</div>
                    </div>
                </div>
            </div>
            <br/>
            <div class="mt-1">
                <button type="submit" [disabled]="storeForm.invalid" class="btn btn-primary btn-user btn-block m-1 col-2 ml-auto">
                    {{isEdit?'Update':'Submit'}}
                </button>
            </div>
        </form>
        <br/>
              <ag-grid-angular 
        [rowData]="rowData" [columnDefs]="colDefs" class="ag-theme-quartz" style="height: 500px;" [defaultColDef]="gridOptions" [gridOptions]="gridOptions" />
    </div>
</div>
<notifier-container></notifier-container>