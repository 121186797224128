import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { SearchPipe } from '../../shared/pipe/SearchPipe';
import { StockService } from '../../core/service/stock.service';
import { AuthenticationService } from '../../core/service/authentication.service';
import { UserStoreMapping } from '../../core/models/IUserProfile';

@Component({
  selector: 'app-levelnrack',
  standalone: true,
  imports: [AgGridAngular,FormsModule],
  templateUrl: './levelnrack.component.html',
  styleUrl: './levelnrack.component.css'
})
export class LevelNRackComponent {
  detailGridApi: any;
  rowDetailFilteredData:any[]=[];
  rowDetailData:any[]=[];
  searchDetailText=""
  constructor(private stockService: StockService,
    private authService: AuthenticationService
  ) { }
  userStoreMapping!:UserStoreMapping;
  ngOnInit(): void {
    this.authService.store$.subscribe(m => {
      this.getLevelNRack(m.storeId);
      this.userStoreMapping = m;
      this.DetailColumnDef();
      this.GridOptionDetail();
    });
  }

  detailColumnDefs : any;

  DetailColumnDef(){
    this.detailColumnDefs= [
      { headerName: 'Product Name', field: 'productName', editable: false, width: 300 },
      { headerName: this.userStoreMapping?.store?.lookup?.lookupItems?.filter(x => x.extra === 'attr1')[0]?.value ?? "Grade", field: 'grade', editable: false },
      { headerName: 'Rack', field: 'rack', editable: false },
      { headerName: 'Level', field: 'level', editable: false },
      {
        headerName: 'Total Box x Qty per box', field: 'totalBox', width: 250
      },
      {
        headerName: 'Quantity Loose', field: 'quantityLoss', width: 150
      },
      {
        headerName: 'Sold Quantity', field: 'soldQuantity', width: 150,
        cellStyle: (params: any) => {
          if (params.value < 0) {
            return { color: 'red' };
          }
          return null;
        }
      },
      { headerName: 'Net Quantity', field: 'netQuantity', editable: false },
  
    ];
  
  }

  gridOptionsDetail:any;
  GridOptionDetail(){
    this.gridOptionsDetail= {
      columnDefs: this.detailColumnDefs,
      rowData: this.rowDetailFilteredData,
      animateRows: true,
      onGridReady: (params: any) => {
        this.detailGridApi = params.api;
      },
      sortable: true, filter: true
    }
  }
  handleDataDetailChange(){
    const searchPipe = new SearchPipe();
    this.rowDetailFilteredData = searchPipe.transform(this.rowDetailData, this.searchDetailText, ['productName', 'soNumber', 'stockType', 'level', 'rack']);
  }
  getLevelNRack(storeId:number){
    // this.detailGridApi.showLoadingOverlay();
    this.stockService.getLevelnRack(storeId).subscribe((resp)=>{
      this.rowDetailFilteredData = resp;
      this.rowDetailData = resp;
      this.detailGridApi.showLoadingOverlay();
    })
  }

}
