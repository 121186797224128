import { Component } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';
import moment from 'moment';
import { NotifierModule, NotifierService } from 'angular-notifier';
import { ViewButtonRendererComponentComponent } from '../../ag-grid/view-button-renderer-component/view-button-renderer-component.component';
import { SearchPipe } from '../../shared/pipe/SearchPipe';
import { StockService } from '../../core/service/stock.service';
import { AuthenticationService } from '../../core/service/authentication.service';
import { UserStoreMapping } from '../../core/models/IUserProfile';
import * as XLSX from 'xlsx';


declare var bootstrap: any;
@Component({
  selector: 'app-stock',
  standalone: true,
  imports: [AgGridAngular, FormsModule, NotifierModule],
  templateUrl: './stock.component.html',
  styleUrl: './stock.component.css'
})
export class StockComponent {
  searchText: string = "";
  searchDetailText: string = "";
  userStoreMapping!: UserStoreMapping;
  constructor(private stockService: StockService, private authService: AuthenticationService, private notifier: NotifierService
  ) {
  
    if (!this.isViewAllowed()) {
      this.columnDefs = this.columnDefs.filter(x => x.headerName != 'Actions');
    }
  }
  ngOnInit(): void {
    this.authService.store$.subscribe(m => {
      this.getStocks(m.storeId);
      this.userStoreMapping = m;
      this.DetailColDef();
      this.GridOptionDetail();
    });
  }

  columnDefs = [
    { headerName: 'Product Name', field: 'name', editable: false, width: 300 },

    { headerName: 'Available Stock', field: 'availableStock' },
    {
      headerName: 'Min. Quantity Alert', field: 'minQuantityAlert', editable: true,
      cellEditor: 'agNumberCellEditor',
      hide: !this.isShowSpecialAllow(),
      cellEditorParams: {
        precision: 0,
        showStepperButtons: true
      }
    },
    {
      headerName: 'Is Product Active', field: 'isActive',
      hide: !this.isShowSpecialAllow(),
      valueGetter: (params: any) => {
        return params.data.isActive == true ? 'Yes' : 'No';
      }
    },
    {
      headerName: 'Actions',
      colId: "action",
      cellRenderer: ViewButtonRendererComponentComponent,
      width: 150,
      cellRendererParams: {
        onView: this.onView.bind(this),
        onActivate: this.onActivateModel.bind(this),
        hide: !this.isShowSpecialAllow()
      }
    }

  ];
  futureColumnDefs = [
    { headerName: 'Product Name', field: 'name', editable: false, width: 300 },

    { headerName: 'Transit Stock', field: 'availableStock' },
    {
      headerName: 'Min. Quantity Alert', field: 'minQuantityAlert', editable: true,
      cellEditor: 'agNumberCellEditor',
      hide: !this.isShowSpecialAllow(),
      cellEditorParams: {
        precision: 0,
        showStepperButtons: true
      }
    },
    {
      headerName: 'Is Product Active', field: 'isActive',
      hide: !this.isShowSpecialAllow(),
      valueGetter: (params: any) => {
        return params.data.isActive == true ? 'Yes' : 'No';
      }
    }
  ];

  min_slowColumnDefs = [
    { headerName: 'Product Name', field: 'name', editable: false, width: 300, tooltipField: "name" },
    { headerName: 'Available Stock', field: 'availableStock', tooltipField: "availableStock" },
    { headerName: 'Min. Quantity Alert', field: 'minQuantityAlert', tooltipField: "minQuantityAlert"},
    { headerName: 'Order Status', field: 'orderStatus', tooltipField: "orderStatus"},
    { headerName: 'Last Updated Date', field: 'lastUpdateDate', tooltipField: "lastUpdateDate" , valueFormatter:this.dateFormatter}
  ];

  
  rowData = [
  ];
  rowFilteredData: any[] = [];
  rowDetailData = [];
  rowFutureFilteredData: any[] = [];
  rowFutureData = [];
  minRowData: any = [];
  minRowFilteredData: any = [];
  slowRowData: any = [];
  slowRowFilteredData: any = [];
  rowDetailFilteredData: any[] = [];
  gridApi: any;
  minGridApi: any;
  slowGridApi: any;
  searchFutureText="";
  detailGridApi: any;
  futureGridApi: any;
  searchMinText=""
  searchSlowText=""
  
  detailColumnDefs:any;

  defaultColDef = {
    sortable: true, filter: true
  };
  DetailColDef(){
    this.detailColumnDefs = [
      { headerName: 'Product Name', field: 'productName', editable: false, width: 300 },
      { headerName: this.userStoreMapping?.store?.lookup?.lookupItems?.filter(x => x.extra === 'attr1')[0]?.value ?? "Grade", field: 'grade', editable: false },
      { headerName: 'Rack', field: 'rack', editable: false },
      { headerName: 'Level', field: 'level', editable: false },
      {
        headerName: 'Total Box x Qty per box', field: 'totalBox', width: 250
      },
      {
        headerName: 'Quantity Loose', field: 'quantityLoss', width: 150
      },
      {
        headerName: 'Sold Quantity', field: 'soldQuantity', width: 150,
        cellStyle: (params: any) => {
          if (params.value < 0) {
            return { color: 'red' };
          }
          return null;
        }
      },
      { headerName: 'Net Quantity', field: 'netQuantity', editable: false },
  
    ];
  }
  dateFormatter(params: any) {
    return moment(params.value).format('DD-MM-YYYY');
  }

  gridOptions = {
    columnDefs: this.columnDefs,
    rowData: this.rowFilteredData,
    animateRows: true,
    tooltipShowDelay: 500,
    onGridReady: (params: any) => {
      this.gridApi = params.api;
    }
  }

  gridOptionsMinimumStock = {
    columnDefs: this.min_slowColumnDefs,
    rowData: this.minRowFilteredData,
    animateRows: true,
    onGridReady: (params: any) => {
      this.minGridApi = params.api;
    }
  }

  gridOptionsShowMoving = {
    columnDefs: this.min_slowColumnDefs,
    rowData: this.slowRowFilteredData,
    animateRows: true,
    onGridReady: (params: any) => {
      this.slowGridApi = params.api;
    }
  }

  gridOptionsDetail:any;

  GridOptionDetail(){
    this.gridOptionsDetail= {
      columnDefs: this.detailColumnDefs,
      rowData: this.rowDetailFilteredData,
      animateRows: true,
      onGridReady: (params: any) => {
        this.detailGridApi = params.api;
      }
    }
  }

  futureGridOptions= {
    columnDefs: this.futureColumnDefs,
    rowData: this.rowFutureFilteredData,
    animateRows: true,
    onGridReady: (params: any) => {
      this.futureGridApi = params.api;
    }
  }

  getFutureStocks(){
    this.futureGridApi.showLoadingOverlay();
    this.stockService.getProductByStock(!this.isShowSpecialAllow(),true,this.userStoreMapping.storeId).subscribe((resp) => {
      this.rowFutureData = resp;
      this.rowFutureFilteredData = resp;
      this.futureGridApi.showLoadingOverlay();
    })
  }
  getStocks(storeId:number) {
     this.gridApi.showLoadingOverlay();
    this.stockService.getProductByStock(!this.isShowSpecialAllow(),false,storeId).subscribe((resp) => {
      this.rowData = resp;
      this.rowFilteredData = resp;
      this.gridApi.showLoadingOverlay();
    })
  }

  getMinStocks() {
    this.minGridApi.showLoadingOverlay();
    this.stockService.getProductByStock(!this.isShowSpecialAllow(),false, this.userStoreMapping.storeId, true).subscribe((resp) => {
      this.minRowData = resp;
      this.minRowFilteredData = resp;
      this.minGridApi.showLoadingOverlay();
    })
  }

  getSlowMovingStocks() {
    
    this.slowGridApi.showLoadingOverlay();
    this.stockService.getProductByStock(!this.isShowSpecialAllow(),false, this.userStoreMapping.storeId, false, true).subscribe((resp) => {
      this.slowRowData = resp;
      this.slowRowFilteredData = resp;
      this.slowGridApi.showLoadingOverlay();
    })
  }
  onView(data: any) {
    this.detailGridApi?.showLoadingOverlay();
    this.searchDetailText = "";
    this.stockService.getStocksByProductId(data.id,this.userStoreMapping.storeId).subscribe((response: any) => {
      this.rowDetailData = response;
      this.rowDetailFilteredData = response;
      this.detailGridApi.showLoadingOverlay();
    });
  }
  activateData: any;
  onActivateModel(data: any) {
    this.activateData = data;
    const myModal = new bootstrap.Modal(document.getElementById('activateModal'));
    myModal.show();
  }
  onActivate() {
    let data = this.activateData;
    this.stockService.updateStatus(data.name, !data.isActive).subscribe((resp) => {
      this.getStocks(this.userStoreMapping.storeId);
      this.notifier.notify('success', 'Status Updated Successfully!');
    }, (error) => {
      this.notifier.notify('error', 'Status Update Failed!');
    });
  }
  handleFutureChange(){
    const searchPipe = new SearchPipe();
    this.rowFutureFilteredData = searchPipe.transform(this.rowFutureData, this.searchFutureText, ['name']);
  
  }
  handleDataDetailChange() {
    const searchPipe = new SearchPipe();
    this.rowDetailFilteredData = searchPipe.transform(this.rowDetailData, this.searchDetailText, ['productName', 'soNumber', 'stockType', 'level', 'rack']);
  }
  handleMinDataDetailChange(){
    const searchPipe = new SearchPipe();
    this.minRowFilteredData = searchPipe.transform(this.minRowData, this.searchMinText, ['name']);
 
  }
  handleSlowDataDetailChange(){
    const searchPipe = new SearchPipe();
    this.slowRowFilteredData = searchPipe.transform(this.slowRowData, this.searchSlowText, ['name']);
 
  }
  handleDataChange() {
    const searchPipe = new SearchPipe();
    this.rowFilteredData = searchPipe.transform(this.rowData, this.searchText, ['name']);
  }
  isViewAllowed() {
    var permission = localStorage.getItem('permission') ?? '[]';
    var jsonPermission = JSON.parse(permission);
    return jsonPermission.find((x: any) => `${x.permission.name}-${x.permission.module.name}` == `Detail-Stock`) != null;
  }
  onCellValueChanged(record: any) {
    this.stockService.updateAlertCount(record.data.id, record.data.minQuantityAlert).subscribe((resp) => {
      this.notifier.notify('success', 'Min. Alert Count Updated Successfully!');

    }, (error) => {
      this.notifier.notify('error', 'Min. Alert Count Update Failed!');
    });
  }

  isShowSpecialAllow() {
    var permission = localStorage.getItem('permission') ?? '[]';
    var jsonPermission = JSON.parse(permission);
    return jsonPermission.find((x: any) => `${x.permission.name}-${x.permission.module.name}` == `Special-Stock`) != null;
  }

  exportToExcel() {
    // Define the columns for both Mapping (for data) and Display (for headers)
    const columns = [
      { Display: 'Product Name', Mapping: 'name'},
      { Display: 'Available Stock', Mapping: 'availableStock' },
      { Display: 'Min. Quantity Alert', Mapping: 'minQuantityAlert'},
      { Display: 'Last Updated Date', Mapping: 'lastUpdateDate'}
    ]

    const header = columns.map(col => col.Display);

    const data = this.minRowFilteredData.map((row: any) => {
      const mappedRow: any = {};

      columns.forEach(col => {
          mappedRow[col.Display] = row[col.Mapping];
        
      });

      return mappedRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(data, { header: header });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const currentDate = new Date().toISOString().split('T')[0];
    const filename = `Low_Stock_${currentDate}.xlsx`;

    XLSX.writeFile(workbook, filename);
  }
}
